<template>
    <v-container fluid class="fill-height text-center mt-0 pt-0">
        <v-card class="fill-height container--fluid" width="100vw" flat>
            <v-row>
                <v-col cols="12" class="align-center justify-center">
                    <v-card :loading="loadingData">
                        <v-form ref="form" v-model="valid" lazy-validation action="/admin/wireframe">
                            <v-card-title class="primary text-h6 grey lighten-2">
                                <span class="headline white--text">{{ formTitle }}</span>
                            </v-card-title>
                            <v-container fluid class="text-center">
                                <v-row class="mt-0">
                                    <v-col cols="12" md="8">
                                        <v-text-field
                                                v-model="editedCompany.name"
                                                label="Nombre"
                                                :rules="textRules"
                                                required>
                                        </v-text-field>
                                        <v-combobox
                                            v-model="editedCompany.address"
                                            :items="places"
                                            :loading="loadingPlaces"
                                            :search-input.sync="searchQuery"
                                            :rules="[v => !!v || 'Dirección de ubicación obligatoria']"
                                            no-data-text="No hay resultados de búsqueda!"
                                            item-text="place_name"
                                            no-filter
                                            clearable
                                            required
                                            label="Dirección de residencia"
                                            v-on="$listeners"
                                            v-on:change="getSelectedItem"
                                            @click:clear="onClear">
                                        </v-combobox>
                                        <v-text-field
                                                v-model="editedCompany.email"
                                                :rules="emailRules"
                                                label="E-mail"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-row no-gutters align="center" justify="center">
                                            <v-img
                                                :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                max-height="150"
                                                max-width="150"
                                                :aspect-ratio="1"
                                                class="white--text align-center justify-center elevation-2">
                                            </v-img>
                                        </v-row>
                                        <v-row no-gutters align="center" justify="center">
                                            <v-file-input
                                                v-model="imageFile"
                                                accept="image/png, image/jpeg, image/bmp"
                                                show-size
                                                clearable
                                                label="Cargar/Cambiar imágen"
                                                placeholder="Archivo de imágen"
                                                prepend-icon="mdi-camera"
                                                @change="changePreviewImage()">
                                            </v-file-input>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <vue-tel-input-vuetify
                                            dense
                                            clearable
                                            required
                                            label="Teléfono 1"
                                            placeholder=""
                                            mode="international"
                                            :rules=textRules
                                            :preferred-countries="['US', 'MX']"
                                            :valid-characters-only="true"
                                            v-model="editedCompany.phone1">
                                        </vue-tel-input-vuetify>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <vue-tel-input-vuetify
                                            dense
                                            clearable
                                            required
                                            label="Teléfono 2"
                                            placeholder=""
                                            mode="international"
                                            :rules=textRules
                                            :preferred-countries="['US', 'MX']"
                                            :valid-characters-only="true"
                                            v-model="editedCompany.phone2">
                                        </vue-tel-input-vuetify>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                                dense
                                                v-model="editedCompany.web_page"
                                                label="Sitio Web"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                                dense
                                                v-model="editedCompany.facebook_profile"
                                                label="Perfil en Facebook"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            dense
                                            v-model="editedCompany.twitter_profile"
                                            label="Cuenta en Twitter"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                                dense
                                                v-model="editedCompany.instagram_profile"
                                                label="Perfil en Instagram"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            dense
                                            v-model="editedCompany.skype_profile"
                                            label="Cuenta de Skype"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                                dense
                                                v-model="editedCompany.youtube_video"
                                                label="Canal de Youtube"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="!valid" color="primary" @click="save">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
                v-model="snackBar"
                :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import companyService from "@/providers/CompanyService";
    
    export default {
        name: "CompanyModuleComponent",
        data: () => ({
            // MapBox Location
            placeNameLimit: 60,
            selectedPlace: {
                place_name: '',
                center: []
            },
            placesList: [],
            loadingPlaces: false,
            searchQuery: null,
            mapBoxApiKey: process.env.VUE_APP_MAPBOX_API_KEY,
            // Default data
            snackBar: false,
            snackText: '',
            focus: false,
            valid: true,
            loadingData: false,
            imageFile: null,
            imagePreview: null,
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
            imageRules: [
                value => !value || value.size < 2000000 || "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
                // this.imagePreview == null || 'La imagen asociada es obligatoria!'
                v => {
                    if (this.imagePreview || this.imagePreview == null) {
                        return 'La imagen asociada es obligatoria!'
                    }
                    if (this.imagePreview && v) {
                        return false
                    }
                    return "Either fill out a complete image, or no image at all."
                }
            ],
            facebookRules: [
                f => !f ? true : this.regexMatchesText('facebook', f) || 'Enter a valid facebook url'
            ],
            editedIndex: -1,
            editedCompany: {
                id: 0,
                name: "",
                address: "",
                email: "",
                web_page: "",
                phone1: "",
                phone2: "",
                map_lat: 0,
                map_lng: 0,
                facebook_profile: "",
                twitter_profile: "",
                instagram_profile: "",
                skype_profile: "",
                youtube_video: "",
                logo_url: ""
            },
            defaultCompany: {
                id: 0,
                name: "",
                address: "",
                email: "",
                web_page: "",
                phone1: "",
                phone2: "",
                map_lat: 0,
                map_lng: 0,
                facebook_profile: "",
                twitter_profile: "",
                instagram_profile: "",
                skype_profile: "",
                youtube_video: "",
                logo_url: ""
            }
        }),
        computed: {
            formTitle() {
                // return this.editedIndex === -1 ? "Nueva Compañía" : "Editar Compañía";
                return "Datos de la Organización";
            },
            places () {
                let listPlaces = [];
                if (this.placesList) {
                    listPlaces = this.placesList.map(entry => {
                        const PlaceName = entry.place_name.length > this.placeNameLimit
                            ? entry.place_name.slice(0, this.placeNameLimit) + '...'
                            : entry.place_name;
                        console.log('PlaceName: ', PlaceName);
                        return Object.assign({}, entry);
                    });
                }
                return listPlaces;
            }
        },
        watch: {
            searchQuery (val) {
                if (this.queryReady(val)) {
                    this.searchPlaces(val);
                }
            },
        },
        methods: {
            queryReady(val) {
                return val !== null && val !== undefined && val !== "";
            },
            onClear() {
                this.$emit("input", null);
                this.$emit("clear");
            },
            async searchPlaces (query) {
                // console.log('val:', val);
                if (query === null || query.length === 0) return;
                // Items have already been loaded
                let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?types=address&access_token=${this.mapBoxApiKey}`;
                this.loadingPlaces = true;
                // Lazily load input items
                fetch(url)
                    .then(res => res.json())
                    .then(res => {
                        // console.log('res: ', res);
                        const { query, features } = res;
                        this.query = query;
                        this.placesList = features;
                        // console.log('places: ', this.places);
                    })
                    .catch(err => {
                        console.log('Error ejecutando API: ', err);
                    })
                    .finally(() => (this.loadingPlaces = false));
            },
            getSelectedItem(item) {
                // console.log('Item selected: ', item);
                this.selectedPlace = Object.assign( {}, item);
            },
            getCompany() {
                // Carga los datos de la Company desde el proveedor de datos...
                this.loadingData = true;
                companyService.getViewData().then(record => {
                    // console.log("record: ", record.value);
                    if (record.value !== null) {
                        this.editedCompany = record.value;
                        // console.log("company: ", this.editedCompany);
                        this.editedIndex = this.editedCompany.id;
                        if (this.editedCompany.logo_url !== null) {
                            this.imagePreview = this.editedCompany.logo_url;
                        } else {
                            this.imagePreview = null;
                        }
                        this.imageFile = null;
                    } else {
                        this.editedCompany = this.defaultCompany;
                        this.newItem();
                    }
                    this.loadingData = false;
                });
            },
            newItem() {
                this.editedIndex = -1;
                this.imagePreview = null;
                this.imageFile = null;
                this.valid = false;
            },
            save() {
                if (this.imagePreview == null) {
                    // console.log('El formulario no contiene un logo asociado!!');
                    this.messageError = 'El formulario no contiene un logo asociado. Por favor agrege uno!!';
                    this.dialogError = true;
                    return;
                }
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
                        this.editedCompany.address = this.selectedPlace.place_name;
                        this.editedCompany.map_lng = this.selectedPlace.center[0];
                        this.editedCompany.map_lat = this.selectedPlace.center[1];
                    }
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        companyService.updateRecord(this.editedCompany, this.imageFile).then(result => {
                            if (result.success) {
                                this.getCompany();
                            }
                        });
                    } else {
                        // Agrega el registro por el metodo POST
                        companyService.addRecord(this.editedCompany, this.imageFile).then(result => {
                            if (result.success) {
                                this.getCompany();
                            }
                        });
                    }
                    this.snackText = 'Datos del formulario guardado con éxito...';
                    this.snackBar = true;
                }
            },
            changePreviewImage() {
                if (this.imageFile) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                        // // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFile);
                    // console.log('Imagen actualizada: ', this.imageFile.name);
                    this.snackText = 'Imágen de perfil cargada con éxito...';
                    this.snackBar = true;
                }
            },
        },
        mounted() {
            this.editedCompany = this.defaultCompany;
            this.getCompany();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>